import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import { Tr } from '@lsoft/translate';
import clsx from 'clsx';
import { MuiLink } from '@lsoft/mui';
import _ from 'lodash';
import { trCurrentSales } from './TagMenuHorizontal.tr';
import {
	PromotionContainer,
	PromotionContext,
} from '@lsoft/shared/components/Promotion/container/Promotion';
import {
	PromotionsContainer,
	PromotionsContext,
	PromotionsDataIterator,
} from '@lsoft/shared/components/Promotion/container/Promotions';

const useStyles = makeStyles((theme) => ({
	ShopMenu: {
		'& ul': {
			listStyleType: 'none',
			margin: 0,
			padding: 0,
			marginBottom: theme.spacing(1),
		},
		'& a': {
			color: 'inherit',
		},
		'& li': {
			paddingLeft: theme.spacing(1),
			paddingBottom: theme.spacing(1),
			cursor: 'pointer',
			lineHeight: 1.2,
		},
		'& li:hover': {
			color: theme.palette.primary[500],
		},
		'& h2': {
			fontWeight: 700,
			marginBottom: theme.spacing(1),
		},
	},
}));

const TagMenuPromotion = () => {
	const { data } = useContext(PromotionContext);
	const link = `/promotion?name=${data.name}`;
	return (
		<li>
			<MuiLink href={link} fullWidth scrollToTop>
				<Typography variant={'body1'} component={'h3'}>
					<Tr tr={data.name_text} />
				</Typography>
			</MuiLink>
		</li>
	);
};

export const TagMenuPromotions = ({ theme }) => {
	const classes = useStyles({ theme });
	const { data } = useContext(PromotionsContext);
	if (_.isEmpty(data)) return null;

	return (
		<div className={clsx(classes.ShopMenu)}>
			<Typography variant={'subtitle1'} component={'h2'}>
				<Tr tr={trCurrentSales} html />
			</Typography>
			<ul>
				<PromotionsDataIterator>
					<PromotionContainer>
						<TagMenuPromotion />
					</PromotionContainer>
				</PromotionsDataIterator>
			</ul>
		</div>
	);
};

export const TagMenu = ({ data, theme }) => {
	const classes = useStyles({ theme });
	const { title, items } = data;
	return (
		<div className={clsx(classes.ShopMenu)}>
			<Typography variant={'subtitle1'} component={'h2'}>
				<Tr tr={title} html />
			</Typography>
			<ul>
				{items
					.filter((item) => _.get(item, 'grouptags', []).length !== 0)
					.map((item, idx) => {
						const tags = item.grouptags.join(',');
						const link = `/group?grouptags=${tags}`;
						return (
							<li key={idx}>
								<MuiLink href={link} fullWidth scrollToTop>
									<Typography variant={'body1'} component={'h3'}>
										<Tr tr={item.title} html />
									</Typography>
								</MuiLink>
							</li>
						);
					})}
			</ul>
		</div>
	);
};
TagMenu.propTypes = {
	data: PropTypes.object,
	variant: PropTypes.string,
};

TagMenu.defaultProps = {
	data: {
		title: {},
		items: [],
	},
	variant: 'sidebar',
};

export const TagMenuLinks = ({ data, theme }) => {
	const classes = useStyles({ theme });
	const { title, items } = data;
	return (
		<div className={clsx(classes.ShopMenu)}>
			<Typography variant={'subtitle1'} component={'h2'}>
				<Tr tr={title} html />
			</Typography>
			<ul>
				{items
					.filter((item) => _.get(item, 'link', []).length !== 0)
					.map((item, idx) => {
						const link = item.link;
						return (
							<li key={idx}>
								<MuiLink href={link} fullWidth scrollToTop>
									<Typography variant={'body1'} component={'h3'}>
										<Tr tr={item.title} html />
									</Typography>
								</MuiLink>
							</li>
						);
					})}
			</ul>
		</div>
	);
};
TagMenuLinks.propTypes = {
	data: PropTypes.object,
	variant: PropTypes.string,
};

TagMenuLinks.defaultProps = {
	data: {
		title: {},
		items: [],
	},
	variant: 'sidebar',
};
