import React, { useContext } from 'react';
import _ from 'lodash';
import { CartPosition } from '../../container/shop/CartPosition';
import { CartPositionSmall } from '../Cart/CartPositionSmall';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { MuiFormatPrice, MuiLink } from '@lsoft/mui';
import { HeaderIconPortal, HeaderIconPortalPaper } from './HeaderIconPortal';
import { Cart } from '../../container/shop/Cart';
import MenuList from '@material-ui/core/MenuList';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { Tr } from '@lsoft/translate';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import ListSubheader from '@material-ui/core/ListSubheader';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import {
	trEditShoppingCart,
	trShoppingCart,
	trValueOfGoods,
} from './Header.tr';
import { GTMProductList } from '../../container/shop/GTMProductList';
import { ProceedToCheckoutButton } from '../ProceedToCheckoutButton';
import { ShopProductContainer } from '../../container/shop/ShopProduct';

const useStyles = makeStyles((theme) =>
	createStyles({
		headerIconPortalPaper: {
			[theme.breakpoints.up('sm')]: {
				minWidth: 444,
			},
		},
		listCartPositions: {
			width: '100%',
		},
	}),
);

export const HeaderCartContent = ({ theme }) => {
	const classes = useStyles(theme);
	const { POSITIONS, GOODS_VALUE } = useContext(Cart.Context);
	const { close } = useContext(HeaderIconPortalButton.Context);
	return (
		<HeaderIconPortal>
			<HeaderIconPortalPaper className={clsx(classes.headerIconPortalPaper)}>
				<List
					subheader={
						<ListSubheader color={'primary'} disableSticky>
							<Tr tr={trShoppingCart} />
						</ListSubheader>
					}
				>
					<ListItem disableGutters style={{ padding: 0 }}>
						<GTMProductList list={'shopping_cart_preview'}>
							<MenuList
								disablePadding
								className={clsx(classes.listCartPositions)}
							>
								{Object.values(!_.isNil(POSITIONS) ? POSITIONS : {}).map(
									(pos) => {
										return (
											<CartPosition.Container key={pos.SKU} SKU={pos.SKU}>
												<ShopProductContainer key={pos.SKU} sku={pos.SKU}>
													<CartPositionSmall />
												</ShopProductContainer>
											</CartPosition.Container>
										);
									},
								)}
							</MenuList>
						</GTMProductList>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={9}>
								<Typography>
									<strong>
										<Tr tr={trValueOfGoods} />
									</strong>
								</Typography>
							</Grid>
							<Grid item xs={3}>
								<Typography align={'right'}>
									<strong>
										<MuiFormatPrice currency={'EUR'} value={GOODS_VALUE} />
									</strong>
								</Typography>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid
							container
							justifyContent={'space-between'}
							alignItems={'center'}
						>
							<Grid item>
								<Button size={'small'} startIcon={<EditIcon />}>
									<MuiLink
										href={'/cart'}
										linkprops={{
											color: 'textPrimary',
											onClick: close,
										}}
									>
										<Tr tr={trEditShoppingCart} />
									</MuiLink>
								</Button>
							</Grid>
							<Grid item>
								<ProceedToCheckoutButton onClick={close} />
							</Grid>
						</Grid>
					</ListItem>
				</List>
			</HeaderIconPortalPaper>
		</HeaderIconPortal>
	);
};
