// noinspection ES6CheckImport
import { MuiCookie, usePrevious, useWebapi } from '@lsoft/mui';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useReducer,
} from 'react';
import _ from 'lodash';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import { getShippingCosts } from './ShippingMethods';
import { minimum_good_values } from '../../constants/shipping_additional.data';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import {
	shop_session_app_get,
	shop_session_login_post,
	shop_session_logout_get,
	shop_session_put,
} from '@lsoft/shared/backend/shop/shop';
import { compose } from '@lsoft/shared/functions/compose';
import { sku_is_voucher } from '@lsoft/shared/functions/product';

const Context = React.createContext(null);
Context.displayName = 'Session.Context';

const debug_log = false;

export const checkAddressValid = (address) => {
	const shouldNotEmptyValues = [
		'FIRSTNAME',
		'LASTNAME',
		'STREET',
		'CITY',
		'ZIPCODE',
		'COUNTRY_CODE',
	];
	const validValues = shouldNotEmptyValues.map((key) => {
		const val = _.get(address, key, null);
		if (_.isNil(val)) return false;
		return val !== '';
	});
	return !_.includes(validValues, false);
};

const checkProfileValid = (state) => {
	const stateData = _.get(state, 'DATA', null);
	const defaultFalse = {
		STANDARD_ADDRESS_VALID: false,
		PROFILE_VALID: false,
	};

	if (_.isNil(stateData)) {
		return defaultFalse;
	}

	const addresses = stateData['ADDRESSES'];
	const standardAddressId = getAddressIdByAddressType(addresses, 'STANDARD');
	if (_.isNil(standardAddressId)) return defaultFalse;

	const standardAddress = addresses[standardAddressId];
	const STANDARD_ADDRESS_VALID = checkAddressValid(standardAddress);
	const PROFILE_VALID =
		STANDARD_ADDRESS_VALID &&
		_.get(stateData, 'CUSTOMER.ACCOUNT_VERIFIED', false);
	return { STANDARD_ADDRESS_VALID, PROFILE_VALID };
};

const checkCustomerTypeSelected = (state) => {
	const stateData = _.get(state, 'DATA', null);
	const email = _.get(stateData, 'CUSTOMER.EMAIL', null);
	return !_.isNil(email) && email !== '';
};

export const getAddressIdByAddressType = (addressesData, type) => {
	if (_.isNil(addressesData)) return null;
	return _.get(
		Object.values(addressesData)
			.filter((entry) => _.includes(entry.ADDRESS_TYPES, type))
			.map((entry) => entry.ID),
		'[0]',
		null,
	);
};

const getDestinationCountryCode = (state) => {
	if (_.isNil(_.get(state, 'DATA', null))) return null;
	const addresses = _.get(state.DATA, 'ADDRESSES', null);
	if (_.isNil(addresses)) return null;
	const deliveryAddressID = getAddressIdByAddressType(addresses, 'DELIVERY');
	if (_.isNil(deliveryAddressID)) return null;
	return addresses[deliveryAddressID].COUNTRY_CODE;
};

const getMinimumOrderValue = (state) => {
	const destCountryCode = getDestinationCountryCode(state);
	return _.get(minimum_good_values, destCountryCode, '50');
};

const calculateShipping = (state) => {
	if (debug_log) console.log('Session->calculateShipping', state);
	const cart = _.get(state, ['DATA', 'CART'], null);
	if (_.isNil(cart)) return null;

	const cart_weight = _.get(cart, 'TOTAL_LOGISTIC_WEIGHT', null);
	const cart_shipping_method = _.get(cart, 'SHIPPING_METHOD', null);
	const used_shipping_method =
		cart_weight !== 0 && cart_shipping_method === 'DIGITAL'
			? 'STANDARD'
			: cart_shipping_method;
	const cart_goods_value = _.get(cart, 'GOODS_VALUE', null);
	const destCountryCode = getDestinationCountryCode(state);
	console.log('used_shipping_method', used_shipping_method);
	// noinspection JSCheckFunctionSignatures
	let price = getShippingCosts(
		used_shipping_method,
		cart_weight,
		destCountryCode,
		cart_goods_value,
	);
	console.log('price', price);
	if (debug_log)
		console.log('Session->calculateShipping->SHIPPING_PRICE:', price);

	let retShippingMethod = used_shipping_method;

	// Check if price is null, if true get price for fallback and update price and shipping method
	if (_.isNil(price)) {
		console.log('price', price);
		const fallbackShippingMethod =
			used_shipping_method !== 'STANDARD' ? 'STANDARD' : 'PREMIUM';

		price = getShippingCosts(
			fallbackShippingMethod,
			cart_weight,
			destCountryCode,
			cart_goods_value,
		);

		/** Ticket #74 - Fix
		 * Make sure calculation for GUEST CUSTOMER starts with STANDARD, after they provided an address
		 * Without fix PREMIUM is preselected for them (since no price can be calculated without address/country)
		 */
		retShippingMethod =
			_.isNil(price) && fallbackShippingMethod === 'PREMIUM'
				? 'STANDARD'
				: fallbackShippingMethod;
	} else if (price === 0 && cart_weight === 0) {
		retShippingMethod = 'DIGITAL';
	}

	return update(state, {
		DATA: {
			CART: {
				SHIPPING_PRICE: { $set: price },
				SHIPPING_METHOD: {
					$set: retShippingMethod,
				},
			},
		},
	});
};

const get_max_points_by_good_value = (state) => {
	const cart = _.get(state, ['DATA', 'CART'], null);
	if (_.isNil(cart)) return null;
	const { POSITIONS } = cart;
	const standard_rate_goods_value = _.reduce(
		Object.values(POSITIONS),
		(result, value) => {
			return value.TAX_TYPE === 'STANDARD_RATE'
				? result + value.AMOUNT * value.PRICE
				: result;
		},
		0,
	);
	return Math.floor(standard_rate_goods_value / 5) * 100;
};

const update_sdc_vip_point_discount = (cart, points_spent) => {
	const vip_points_discount = _.get(
		cart,
		['SURCHARGE_DISCOUNT_COST', 'VIP_POINTS_DISCOUNT'],
		{},
	);

	return _.merge({}, vip_points_discount, {
		AMOUNT: points_spent,
		TOTAL_PRICE: -Math.abs(points_spent * 0.05),
	});
};

const calculateTotalPrice = (state) => {
	const new_state = _.cloneDeep(state);

	const cart = _.get(new_state, ['DATA', 'CART'], null);
	if (_.isNil(cart)) return null;

	const {
		POINTS_SPENT,
		GOODS_VALUE: goods_value,
		SHIPPING_PRICE: shipping_price,
		SURCHARGE_DISCOUNT_COST,
		POSITIONS: positions,
	} = cart;

	const voucher_value =
		Math.round(
			_.sum(
				Object.values(positions).map((pos) => {
					if (_.isNil(pos)) return 0;
					if (!sku_is_voucher(pos.SKU)) return 0;
					return Math.round(pos.AMOUNT * pos.PRICE * 100) / 100;
				}),
			) * 100,
		) / 100;

	const points_max = get_max_points_by_good_value(new_state);
	const points_spent = POINTS_SPENT > points_max ? points_max : POINTS_SPENT;

	const total_discount = _.reduce(
		Object.values(SURCHARGE_DISCOUNT_COST),
		(result, sdc_item) => {
			if (sdc_item.MODE === 'DISCOUNT') {
				if (
					sdc_item.TYPE === 'VIP_POINTS_DISCOUNT' &&
					points_spent !== cart.POINTS_SPENT
				) {
					new_state.DATA.CART.SURCHARGE_DISCOUNT_COST.VIP_POINTS_DISCOUNT =
						update_sdc_vip_point_discount(cart, points_spent);
					return (
						result +
						new_state.DATA.CART.SURCHARGE_DISCOUNT_COST.VIP_POINTS_DISCOUNT
							.TOTAL_PRICE
					);
				}
				return result + sdc_item.TOTAL_PRICE;
			}
			return result;
		},
		0,
	);

	const total_price =
		Math.round((goods_value + shipping_price + total_discount) * 100) / 100;

	const points_gain = Math.floor(goods_value + total_discount - voucher_value);

	return update(new_state, {
		DATA: {
			CART: {
				TOTAL_PRICE: { $set: total_price },
				POINTS_GAIN: { $set: points_gain },
				POINTS_MAX: { $set: points_max },
				POINTS_SPENT: { $set: points_spent },
			},
		},
	});
};

const commonStateTransforms = (state) => {
	if (debug_log) console.log('Session->commonStateTransforms', state);
	return compose(calculateTotalPrice, calculateShipping)(state);
};

const SessionReducer = (state, action) => {
	if (action.type === 'replaceSession') {
		if (debug_log)
			console.log('Session->replaceSession action.payload:', action.payload);
		return !_.isNil(action.payload.data)
			? commonStateTransforms(_.assign({}, action.payload.data))
			: null;
	} else {
		if (!_.isNil(state)) {
			switch (action.type) {
				case 'updatePath':
					if (debug_log)
						console.log('Session->updatePath:', action.payload.path);
					return commonStateTransforms(
						update(
							state,
							_.set({}, ['DATA', action.payload.path], {
								$set: action.payload.data,
							}),
						),
					);
			}
		}
	}
	throw new Error(`no reducer for action '${action.type}'`);
};

const load = async (_webapi_ca, _webapi_client_id, appName) => {
	if (debug_log)
		console.log('Session->load->cookies:', { _webapi_ca, _webapi_client_id });

	// if (!_webapi_ca) return null; // Removed to prevent session/client id bug #207
	if (_.isNil(_webapi_client_id)) return null;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const webapi = useWebapi();
	webapi.cookies = { _webapi_ca, _webapi_client_id };

	try {
		const session = await shop_session_app_get({ app_name: appName });
		if (debug_log) console.log('Session->load->session:', session.SESSION_ID);
		return session;
	} catch (e) {
		const util = require('util');
		console.error(util.inspect(e, false, null, true /* enable colors */));
		return null;
	}
};

const defaultSession = {
	DATA: {
		CART: {
			POSITIONS: {},
			PAYMENT_METHOD: '',
			SHIPPING_METHOD: '',
			GOODS_VALUE: 0,
			POINTS_GAIN: 0,
			POINTS_SPENT: 0,
			POINTS_MAX: 0,
		},
	},
};

// temporary until backend returns right default structure
const makeDefaultSession = (session) => {
	return _.isNil(session) ? null : _.merge({}, defaultSession, session);
};

const Container = ({ children, session, appName }) => {
	const [state, dispatch] = useReducer(
		SessionReducer,
		makeDefaultSession(session),
		(x) => x,
	);

	const throwSnackbar = useShopSnackbar();

	const prevState = usePrevious(state);
	const stateChanged = useMemo(
		() => !_.isEqual(state, prevState),
		[state, prevState],
	);

	const DESTINATION_COUNTRY_CODE = useMemo(
		() => getDestinationCountryCode(state),
		[state],
	);

	const MINIMUM_ORDER_VALUE = useMemo(
		() => getMinimumOrderValue(state),
		[state],
	);

	const session_id = _.get(state, 'SESSION_ID', null);
	const prev_session_id = usePrevious(session_id);

	const session_id_change = useMemo(
		() => !_.isNil(prev_session_id) && session_id !== prev_session_id,
		[session_id, prev_session_id],
	);

	const { technicalCookies, _webapi_client_id } = useContext(MuiCookie.Context);

	useEffect(() => {
		if (!session_id_change && stateChanged) {
			// noinspection JSIgnoredPromiseFromCall
			saveSession();
		}
	}, [state]);

	useEffect(() => {
		if (debug_log && session_id_change)
			console.log(`Session->session changed: ${session_id}`);
	}, [session_id, session_id_change]);

	const saveSession = useCallback(async () => {
		if (stateChanged) {
			if (debug_log) console.log('Session->saveSession:', state.SESSION_ID);
			try {
				if (debug_log) console.log(state);
				await shop_session_put({
					session_id: state.SESSION_ID,
					session: state,
				});
			} catch (e) {
				throwSnackbar({
					exception: e,
					msg: 'Session save failed.',
					variant: 'error',
				});
			}
		}
	}, [appName, state, stateChanged]);

	const replaceSession = useCallback(
		async (session) => {
			dispatch({
				type: 'replaceSession',
				payload: { data: session },
			});
		},
		[dispatch],
	);

	const updatePath = useCallback(
		async (path, data) => {
			dispatch({
				type: 'updatePath',
				payload: { path, data },
			});
		},
		[dispatch],
	);

	const loadSession = useCallback(async () => {
		if (debug_log)
			console.log('Session->loadSession->cookies', {
				technicalCookies,
				_webapi_client_id,
			});
		const session = await load(technicalCookies, _webapi_client_id, appName);
		if (debug_log)
			console.log('Session->loadSession->session', session.SESSION_ID);
		const defaultSession = makeDefaultSession(session);
		await replaceSession(defaultSession);
	}, [technicalCookies, _webapi_client_id, appName, replaceSession]);

	useEffect(() => {
		if (_.isNil(session) && !_.isNil(_webapi_client_id)) {
			loadSession().then(() => {
				if (debug_log) console.log('Session->loadSession->then: loaded');
			});
		}
	}, [_webapi_client_id, session]);

	const login = useCallback(
		async ({ password, username }) => {
			// eslint-disable-next-line no-useless-catch
			try {
				const res = await shop_session_login_post({
					app_name: appName,
					password: password,
					username: username,
				});
				const session = res.session;
				await replaceSession(makeDefaultSession(session));
				return res;
			} catch (e) {
				throw e;
			}
		},
		[appName],
	);

	const logout = useCallback(async () => {
		try {
			const session = await shop_session_logout_get({ app_name: appName });
			await replaceSession(makeDefaultSession(session));
			return session;
		} catch (e) {
			throwSnackbar({
				exception: e,
				msg: 'Session logout failed.',
				variant: 'error',
			});
		}
		if (debug_log) console.log('logout');
		return {};
	}, [appName, replaceSession, throwSnackbar]);

	const profileAndStandardAddressValid = useMemo(
		() => checkProfileValid(state),
		[state],
	);

	const CUSTOMER_TYPE_SELECTED = useMemo(
		() => checkCustomerTypeSelected(state),
		[state],
	);
	const value =
		state === null
			? {}
			: {
					...state,
					loadSession,
					saveSession,
					replaceSession,
					updatePath,
					login,
					logout,
					CUSTOMER_TYPE_SELECTED,
					DESTINATION_COUNTRY_CODE,
					MINIMUM_ORDER_VALUE,
					...profileAndStandardAddressValid,
				};

	if (debug_log) console.log('Session Context.Provider value', value);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
Container.propTypes = {
	session: PropTypes.object,
	appName: PropTypes.string,
};
Container.displayName = 'Session.Container';
Container.propTypes = {};
Container.defaultProps = {};

const Session = {
	Container,
	Context,
	load,
};

export { Session };
