import _ from 'lodash';

const debug_log = false;

export const new_custom_error = ({ error_class, error_msg }) => {
	// 1. Create new error
	let new_error = new Error();

	// 2. Populate error variables with paras
	new_error.name = error_class;
	new_error.message = error_msg;

	// 3. Return the new shiny Error()
	return new_error;
};

export const convert_axios_error = (exception) => {
	// 1. If exception is null, return exception
	if (_.isNil(exception)) return null;

	// 2. Try to get response of exception, if == null, return exception
	const { response } = exception;
	if (_.isNil(response)) return exception;

	// 3. Check if response.data.detail has ERROR_CLASS, if == true, return response.data.detail
	const has_error_class = _.has(response, ['data', 'detail', 'ERROR_CLASS']);
	if (has_error_class) return _.get(response, ['data', 'detail']);

	const has_lowercase_error_class = _.has(response, [
		'data',
		'detail',
		'error_class',
	]);
	if (has_lowercase_error_class)
		return {
			ERROR_CLASS: _.get(response, ['data', 'detail', 'error_class']),
			ERROR: JSON.stringify(_.get(response, ['data'], {})),
			ERROR_STATUS_CODE: _.get(response, [
				'data',
				'detail',
				'error_status_code',
			]),
		};

	// 4. Check if response has status && statusText, if == true, return object with { ERROR_CLASS, ERROR }
	if (_.has(response, ['status']) && _.has(response, ['statusText'])) {
		return {
			ERROR_CLASS: _.get(response, ['statusText']),
			ERROR: JSON.stringify(_.get(response, ['data'], {})),
		};
	}

	// 5. Default - Return exception
	return exception;
};

export const parse_error_to_js_error = (real_exception) => {
	// 1. Convert & set exception
	const exception = convert_axios_error(real_exception);
	if (debug_log) console.log('real_exception', real_exception);
	if (debug_log) console.log('exception', exception);

	// 2. If exception is already JS Error, return exception
	if (exception instanceof Error) return exception;

	// 3. Determinate error class
	const error_class = _.isString(exception)
		? exception
		: _.get(exception, 'ERROR_CLASS');
	if (debug_log) console.log('error_class', error_class);

	// 4. Return exception if error_class is null
	if (_.isNil(error_class)) return new Error(exception);

	// 5. Determinate error msg
	const error_msg = _.get(exception, 'ERROR', error_class);
	if (debug_log) console.log('error_msg', error_msg);

	// 6. Return new custom error
	return new_custom_error({ error_class, error_msg });
};
