import { TTranslation } from '@shared/types';
import _ from 'lodash';
import { useRouter } from 'next/router';

export const tr = (tr: string | TTranslation | undefined) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { locale } = useRouter();
  if (_.isNil(tr)) return null;
  if (Object.keys(tr).length === 0) return null;
  if (_.isString(tr)) return tr;
  // const locale = 'de';
  const d = _.mapKeys(tr, (v: string, k: string) => {
    return k.toLowerCase();
  });

  return (
    _.get(d, 'all') ||
    _.get(d, locale ?? 'en') ||
    _.get(d, 'en') ||
    _.get(d, '[0]')
  );
};
