import React, { useCallback, useMemo } from 'react';
import { Session } from './Session';
import _ from 'lodash';
import update from 'immutability-helper';
import { makeSubContainer } from '../../tools/containerTools';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { getTranslation } from '@lsoft/translate';
import {
	trArticleSuccessFullAddedToCart,
	trYourShoppingCartHasBeenUpdated,
} from './Cart.tr';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { sku_is_voucher } from '@lsoft/shared/functions/product';

const debug_log = false;
const single_point_value = 0.05; // Base data

const Context = React.createContext(null);
Context.displayName = 'Cart.Context';

const convert_points = (state, point_count) => {
	const new_state = _.cloneDeep(state);

	if (debug_log) console.log('convert_points state', state);

	new_state.POINTS_SPENT = point_count;

	if (point_count !== 0) {
		new_state.SURCHARGE_DISCOUNT_COST['VIP_POINTS_DISCOUNT'] = {
			AMOUNT: point_count,
			INDEX_NR: new_state.SURCHARGE_DISCOUNT_COST.length - 1,
			MODE: 'DISCOUNT',
			PRICE: -Math.abs(single_point_value),
			TOTAL_PRICE: -Math.abs(single_point_value * point_count),
			TYPE: 'VIP_POINTS_DISCOUNT',
			NAME: 'Rabatt durch Vip-Punkte',
		};
	} else {
		delete new_state.SURCHARGE_DISCOUNT_COST['VIP_POINTS_DISCOUNT'];
	}
	if (debug_log) console.log('convert_points new_state', new_state);

	return new_state;

	// const point_value = (point_count / 100) * 5;
	// const goods_value_factor = state.GOODS_VALUE / point_value;
	//
	// new_state.POSITIONS = {};
	//
	// Object.values(state.POSITIONS).map((position) => {
	// 	const new_position = _.cloneDeep(position);
	// 	const price = -Math.abs(position.PRICE / goods_value_factor);
	// 	new_position.SURCHARGE_DISCOUNT_COST = []; // This is wrong, deal with it
	// 	new_position.SURCHARGE_DISCOUNT_COST.push({
	// 		MODE: 'POINT_DISCOUNT',
	// 		AMOUNT: position.AMOUNT,
	// 		PRICE: price,
	// 		TOTAL_PRICE: price * position.AMOUNT,
	// 	});
	// 	new_state.POSITIONS[position.SKU] = new_position;
	// });

	// return update_positions(new_state, new_state.POSITIONS);
};

const update_positions = (state, positions) => {
	const goodsValue =
		Math.round(
			_.sum(
				Object.values(positions).map((pos) => {
					if (_.isNil(pos)) return 0;
					return Math.round(pos.AMOUNT * pos.PRICE * 100) / 100;
				}),
			) * 100,
		) / 100;

	const ret = update(state, {
		GOODS_VALUE: {
			$set: goodsValue,
		},
		POSITIONS: { $set: positions },
	});

	if (debug_log) console.log('update_positions new_state', ret);

	return ret;
};

const adjust_position = (state, position) => {
	const skuStr = position.SKU.toString();
	const positions =
		position.AMOUNT === 0
			? _.omit(state.POSITIONS, [skuStr])
			: _.assign({}, state.POSITIONS, { [skuStr]: position });
	return update_total_weight(update_positions(state, positions));
};

export const convert_raw_product_data_to_session_product = (
	SKU,
	AMOUNT,
	PRODUCT_DATA,
) => {
	return {
		SKU: SKU,
		AMOUNT: AMOUNT,
		NAME_TEXT: PRODUCT_DATA.name_text,
		PRICE: PRODUCT_DATA.real_brutto_sp,
		ACTIVE: PRODUCT_DATA.status,
		LOGISTIC_WEIGHT: PRODUCT_DATA.logistic_weight,
		TAX_TYPE: PRODUCT_DATA.tax_type,
		AVAILABLE: PRODUCT_DATA.delivery_time_working_days === 1,
	};
};

const update_total_weight = (state) => {
	const TOTAL_LOGISTIC_WEIGHT =
		Math.round(
			_.sum(
				Object.values(state.POSITIONS).map((pos) => {
					if (_.isNil(pos)) return 0;
					return Math.round(pos.AMOUNT * pos.LOGISTIC_WEIGHT * 1000) / 1000;
				}),
			) * 100,
		) / 100;
	return _.assign({}, state, { TOTAL_LOGISTIC_WEIGHT });
};

const cart_reducer = (state, action) => {
	switch (action.type) {
		case 'adjustPosition':
			return adjust_position(state, action.payload);
		case 'addPosition':
			return adjust_position(state, {
				...action.payload,
				AMOUNT:
					_.get(state.POSITIONS, [action.payload.SKU.toString(), 'AMOUNT'], 0) +
					action.payload.AMOUNT,
			});
		case 'convert_points':
			const { point_count } = action.payload;
			return convert_points(state, point_count);
		case 'setCartTaxRates':
			return _.assign({}, state, { TAX_TYPES: action.payload });
		case 'setShippingMethod':
			return _.assign({}, state, {
				SHIPPING_METHOD: action.payload.SHIPPING_METHOD,
			});

		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};

const CartContainer = ({ state, dispatch, makeValues, children }) => {
	const { locale } = useRouter();
	const throwSnackbar = useShopSnackbar();

	const unavailable_pos_in_cart = useMemo(() => {
		const positions = _.get(state, 'POSITIONS', {});
		return !_.isNil(
			Object.values(positions).find((position) => !position.AVAILABLE),
		);
	}, [state]);

	const voucher_only = useMemo(() => {
		const positions = _.get(state, 'POSITIONS', {});
		if (positions.length === 0) {
			return false;
		}
		return _.every(
			Object.values(positions).map((p) => sku_is_voucher(p.SKU)),
			Boolean,
		);
	}, [state]);

	const voucher_exists = useMemo(() => {
		const positions = _.get(state, 'POSITIONS', {});
		if (positions.length === 0) {
			return false;
		}
		return _.some(
			Object.values(positions).map((p) => sku_is_voucher(p.SKU)),
			Boolean,
		);
	}, [state]);

	const convert_points = useCallback((point_count) => {
		dispatch({
			type: 'convert_points',
			payload: { point_count },
		});
	});

	const position_add = useCallback(
		(PRODUCT_DATA) => {
			dispatch({
				type: 'addPosition',
				payload: { ...PRODUCT_DATA },
			});
			throwSnackbar({
				msg: getTranslation(trArticleSuccessFullAddedToCart, locale),
				variant: 'success',
			});
		},
		[locale],
	);

	const position_adjust = useCallback(
		(PRODUCT_DATA) => {
			dispatch({
				type: 'adjustPosition',
				payload: { ...PRODUCT_DATA },
			});
			throwSnackbar({
				msg: getTranslation(trYourShoppingCartHasBeenUpdated, locale),
				variant: 'success',
			});
		},
		[locale],
	);

	const position_remove = useCallback(
		(SKU) => {
			dispatch({
				type: 'adjustPosition',
				payload: { SKU, AMOUNT: 0 },
			});
			throwSnackbar({
				msg: getTranslation(trYourShoppingCartHasBeenUpdated, locale),
				variant: 'success',
			});
		},
		[locale],
	);

	const set_shipping_method = useCallback((SHIPPING_METHOD) => {
		dispatch({
			type: 'setShippingMethod',
			payload: { SHIPPING_METHOD },
		});
	}, []);

	const set_cart_tax_rates = useCallback((taxRates) => {
		dispatch({
			type: 'setCartTaxRates',
			payload: taxRates,
		});
	}, []);

	const total_item_count = useMemo(() => {
		if (_.isNil(state)) return 0;
		else return _.sum(Object.values(state.POSITIONS).map((pos) => pos.AMOUNT));
	}, [state]);

	const value = makeValues({
		convert_points,
		position_add,
		position_adjust,
		position_remove,
		setShippingMethod: set_shipping_method,
		setCartTaxRates: set_cart_tax_rates,
		total_item_count,
		unavailable_pos_in_cart,
		voucher_only,
		voucher_exists,
		...state,
	});

	if (debug_log) console.log('Cart.Context state', state);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
CartContainer.propTypes = {
	state: PropTypes.object,
	makeValues: PropTypes.func.isRequired,
};

const Container = makeSubContainer(
	Session.Context,
	'CART',
	cart_reducer,
)(CartContainer);
Container.displayName = 'Cart.Container';

export const Cart = {
	Container,
	Context,
};
