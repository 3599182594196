import { TLsShopCategory } from '@ui/LsShopCategoriesView';

export const cobiCategories: TLsShopCategory[] = [
  {
    imageSrc: '/images/categories/cobi/maserati24334.png',
    text: {
      ALL: 'Executive Edition',
    },
    href: '/group?grouptags=SERIES/COBI/EXECUTIVE_EDITION',
  },
  {
    imageSrc: '/images/categories/cobi/dodgeram.png',
    text: {
      ALL: 'Dodge RAM',
    },
    href: '/group?grouptags=SERIES/COBI/DODGE_RAM',
  },
  {
    imageSrc: '/images/categories/cobi/decapotable24346.png',
    text: {
      EN: 'Cars',
      DE: 'Autos',
      FR: 'Voitures',
    },
    href: '/group?grouptags=SERIES/COBI/MODEL_CARS_1:12_SCALE',
  },
  {
    imageSrc: '/images/categories/cobi/fiat24530.png',
    text: {
      ALL: 'Youngtimer',
    },
    href: '/group?grouptags=SERIES/COBI/YOUNGTIMER_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/titanic1929.png',
    text: {
      ALL: 'Titanic',
    },
    href: '/group?grouptags=SERIES/COBI/TITANIC_HISTORICAL_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/concorde1917.png',
    text: {
      EN: 'Historical Collection',
      DE: 'Historische Sammlung',
      FR: 'Collection historique',
    },
    href: '/group?grouptags=SERIES/COBI/HISTORICAL_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/kriegslokomotive6281.png',
    text: {
      EN: 'Trains',
      DE: 'Züge',
      FR: 'Trains',
    },
    href: '/group?grouptags=SERIES/COBI/TRAINS',
  },
  {
    imageSrc: '/images/categories/cobi/skyhawk26621.png',
    text: {
      EN: 'Planes',
      DE: 'Flugzeuge',
      FR: 'Avions',
    },
    href: '/group?grouptags=SERIES/COBI/CIVIL_AVIATION',
  },
  {
    imageSrc: '/images/categories/cobi/dreamliner26603.png',
    text: {
      ALL: 'Boeing',
    },
    href: '/group?grouptags=SERIES/COBI/BOEING',
  },
  {
    imageSrc: '/images/categories/cobi/topgun.png',
    text: {
      ALL: 'Top Gun',
    },
    href: '/group?grouptags=SERIES/COBI/TOP_GUN',
  },
  {
    imageSrc: '/images/categories/cobi/redbaron2986.png',
    text: {
      EN: 'Great War',
      DE: 'Erster Weltkrieg',
      FR: 'Première Guerre mondiale',
    },
    href: '/group?grouptags=SERIES/COBI/GREAT_WAR_HISTORICAL_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/arizona4843.png',
    text: {
      EN: 'World War 2',
      DE: 'Zweiter Weltkrieg',
      FR: 'Seconde Guerre mondiale',
    },
    href: '/group?grouptags=SERIES/COBI/WW2_HISTORICAL_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/corsair2417.png',
    text: {
      EN: 'Korean War',
      DE: 'Koreanischer Krieg',
      FR: 'Guerre de Corée',
    },
    href: '/group?grouptags=SERIES/COBI/KOREAN_WAR_HISTORICAL_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/freedomfighter2425.png',
    text: {
      EN: 'Vietnam War',
      DE: 'Vietnamkrieg',
      FR: 'Guerre de Vietnam',
    },
    href: '/group?grouptags=SERIES/COBI/VIETNAM_WAR_HISTORICAL_COLLECTION',
  },
  {
    imageSrc: '/images/categories/cobi/blackhawk5817.png',
    text: {
      ALL: 'Armed Forces',
    },
    href: '/group?grouptags=SERIES/COBI/ARMED_FORCES',
  },
  {
    imageSrc: '/images/categories/cobi/tiger2710.png',
    text: {
      EN: 'Tanks - Scale 1:48',
      DE: 'Panzer - Maßstab 1:48',
      FR: 'Chars - Échelle 1:48',
    },
    href: '/group?grouptags=SERIES/COBI/TANKS_1:48_SCALE',
  },
  {
    imageSrc: '/images/categories/cobi/t-34_3088.png',
    text: {
      EN: 'Tanks - Scale 1:72',
      DE: 'Panzer - Maßstab 1:72',
      FR: 'Chars - Échelle 1:72',
    },
    href: '/group?grouptags=SERIES/COBI/TANKS_1:72_SCALE',
  },
  {
    imageSrc: '/images/categories/cobi/companyofheroes3.png',
    text: {
      ALL: 'Company of Heroes 3',
    },
    href: '/group?grouptags=SERIES/COBI/COMPANY_OF_HEROES_3',
  },

  // {
  //   imageSrc: '/images/categories/cobi/p51mustang.png',
  //   text: {
  //     EN: 'Block Games',
  //     DE: 'Block Spiele',
  //     FR: 'Jeux de blocs',
  //   },
  //   href: '/group?grouptags=SERIES/COBI/BOARD_GAME',
  // },
  // {
  //   imageSrc: '/images/categories/cobi/p51mustang.png',
  //   text: {
  //     EN: 'Cold War',
  //     DE: 'Kalter Krieg',
  //     FR: 'Guerre froide',
  //   },
  //   href: '/group?grouptags=COLD_WAR_HISTORICAL_COLLECTION',
  // },
  // {
  //   imageSrc: '/images/categories/cobi/p51mustang.png',
  //   text: {
  //     EN: 'Key chains',
  //     DE: 'Schlüsselanhänger',
  //     FR: 'Porte-clés',
  //   },
  //   href: '/group?grouptags=SERIES/COBI/KEYCHAINS',
  // },
];
