export const voucher_sku = 900000;
const voucher_min_sku = 900010;
const voucher_max_sku = 901000;

export const sku_is_voucher = (sku: number): boolean => {
  return voucher_sku <= sku && sku <= voucher_max_sku;
};

export const voucher_get_price = (sku: number): number => {
  if (!sku_is_voucher(sku)) {
    throw Error('Here is something wrong!');
  }

  return sku - voucher_sku;
};
