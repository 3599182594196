import { cobiCategories } from './cobiCategories';
import _ from 'lodash';

export const MenuLegoShopperButton = {
	title: { ALL: 'LEGO® ShopperButton' },
	items: [
		{
			title: {
				DE: 'Brandneue Produkte',
				EN: 'Brand-new products',
				FR: 'Nouveaux produits',
			},
			src: {
				DE: '/images/tags/TAG-SERIES-LEGO+NEW.de.png',
				EN: '/images/tags/TAG-SERIES-LEGO+NEW.en.png',
				FR: '/images/tags/TAG-SERIES-LEGO+NEW.fr.png',
			},
			grouptags: ['NEW'],
		},
		{
			title: {
				DE: 'Seltene Produkte',
				EN: 'Rare products',
				FR: 'Produits rares',
			},
			src: {
				DE: '/images/tags/TAG-SERIES-LEGO+RARE.de.png',
				EN: '/images/tags/TAG-SERIES-LEGO+RARE.en.png',
				FR: '/images/tags/TAG-SERIES-LEGO+RARE.fr.png',
			},
			grouptags: ['RARE'],
		},
	],
};

export const MenuLegoEducationStem = {
	title: { EN: 'STEAM', DE: 'MINT' },
	description: {
		headline: { DE: 'Spielerisch lernen', EN: 'Playful learning' },
		text: {
			DE: 'Erleben Sie spannende MINT-Lernkonzepte für Klassenräume und für zuhause.',
			EN: 'Experience exciting STEAM learning concepts in classrooms and at home.',
		},
	},
	src: '/images/tags/TAG-SERIES-LEGO_EDUCATION.png',
	items: [
		{
			title: {
				DE: 'Kindergarten',
				EN: 'Early Learning',
				FR: 'École Maternelle',
			},
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-PRESCHOOL.png',
			grouptags: ['SERIES/LEGO_EDUCATION/', 'STEM/PRE_SCHOOL'],
		},
		{
			title: {
				DE: 'Grundschule',
				EN: 'Primary School',
				FR: 'École primaire',
			},
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-PRIMARY.png',
			grouptags: ['SERIES/LEGO_EDUCATION/', 'STEM/PRIMARY_SCHOOL'],
		},
		{
			title: {
				DE: 'Weiterführende Schule',
				EN: 'Secondary School',
				FR: 'École secondaire',
			},
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-SECONDARY.png',
			grouptags: ['SERIES/LEGO_EDUCATION/', 'STEM/SECONDARY_SCHOOL'],
		},
		{
			title: {
				DE: 'Zubehör',
				EN: 'Accessories',
				FR: 'Accessoires',
			},
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-STORAGE.png',
			grouptags: ['SERIES/LEGO_EDUCATION/STORAGE'],
		},
	],
};

export const MenuOtherBrands = {
	title: {
		DE: 'Marken',
		EN: 'Brands',
		FR: 'Marques',
	},
	// description: {
	// 	headline: {
	// 		DE: 'Neu für Sie',
	// 		EN: 'New for you',
	// 		FR: 'Nouveau pour vous',
	// 	},
	// 	text: {
	// 		DE: 'Entdecken Sie weitere spannende Spielwaren von bekannten Marken.',
	// 		EN: 'Discover more exciting toys from well-known brands.',
	// 		// prettier-ignore
	// 		FR:
	// 			'Découvrez d\'autres jouets passionnants de marques connues.',
	// 	},
	// },
	items: [
		{
			title: { ALL: 'LEGO®' },
			grouptags: ['MANUFACTURER/LEGO'],
		},
		{
			title: { ALL: 'COBI' },
			grouptags: ['BRANDS/COBI'],
		},
		{
			title: { ALL: 'MEGA / MEGA Construx' },
			grouptags: ['BRANDS/MEGA'],
		},
		{
			title: { ALL: 'Hot Wheels' },
			grouptags: ['BRANDS/HOT_WHEELS'],
		},
		{
			title: { ALL: 'Barbie' },
			grouptags: ['BRANDS/BARBIE'],
		},
		{
			title: { ALL: 'Pokémon' },
			grouptags: ['BRANDS/POKEMON'],
		},
		{
			title: { ALL: 'Bruder' },
			grouptags: ['BRANDS/BRUDER'],
		},
	],
};

export const MenuLegoEducationSeries = {
	title: { ALL: 'LEGO® Education' },
	description: {
		headline: {
			DE: 'Spielerisch lernen',
			EN: 'Playful learning',
			FR: 'Apprendre par le jeu',
		},
		text: {
			DE: 'Erleben Sie spannende MINT-Lernkonzepte für Klassenräume und für zuhause.',
			EN: 'Experience exciting STEM learning concepts in classrooms and at home.',
			// prettier-ignore
			FR:
				'Faites l\'expérience de concepts d\'apprentissage STEM passionnants dans les salles de classe et à la maison.',
		},
	},
	src: '/images/tags/TAG-SERIES-LEGO_EDUCATION.png',
	items: [
		{
			title: { ALL: 'LEGO® Education Early Learning' },
			src: {
				DE: '/images/tags/TAG-SERIES-LEGO_EDUCATION-EARLY_LEARNING.de.png',
				EN: '/images/tags/TAG-SERIES-LEGO_EDUCATION-EARLY_LEARNING.en.png',
			},
			grouptags: ['SERIES/LEGO_EDUCATION/EARLY_LEARNING '],
		},
		{
			title: {
				ALL: 'LEGO® Education WeDo 2.0',
			},
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-WE_DO_2_0.png',
			grouptags: ['SERIES/LEGO_EDUCATION/WE_DO_2_0'],
		},
		{
			title: { ALL: 'SPIKE™ Prime' },
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-SPIKE_PRIME.png',
			grouptags: ['SERIES/LEGO_EDUCATION/SPIKE_PRIME'],
		},
		{
			title: { ALL: 'MINDSTORMS® EV3' },
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-MINDSTORMS_EV3.png',
			grouptags: ['SERIES/LEGO_EDUCATION/MINDSTORMS_EV3'],
		},
		{
			title: { ALL: 'BricQ Motion' },
			src: '/images/tags/TAG-SERIES-LEGO_EDUCATION-BRICQ-MOTION.png',
			grouptags: ['SERIES/LEGO_EDUCATION/BRICQ'],
		},
		{
			title: {
				DE: 'Aufbewahrungsboxen',
				EN: 'Storage boxes',
				FR: 'Boîtes de stockage',
			},
			src: {
				DE: '/images/tags/TAG-SERIES-LEGO_EDUCATION-STORAGE.de.png',
				EN: '/images/tags/TAG-SERIES-LEGO_EDUCATION-STORAGE.en.png',
			},
			grouptags: ['SERIES/LEGO_EDUCATION/STORAGE'],
		},
	],
};

export const MenuGiftcard = {
	title: {
		DE: 'Geschenkkarten',
		EN: 'Gift cards',
		FR: 'Cards-cadeaux',
	},
	description: {
		headline: {
			DE: 'Geschenkkarten',
			EN: 'Gift cards',
			FR: 'Cards-cadeaux',
		},
		text: {
			DE: 'Alles rund um unsere Geschenkkarten.',
			// prettier-ignore
			EN:
				'Everything about our gift cards.',
			// prettier-ignore
			FR: 'Tout sur nos cartes-cadeaux.',
		},
	},
	src: '/images/voucher/221129_RZ_Gutscheinkarte_1.png',
	items: [
		{
			title: { DE: 'Kaufen' },
			src: '/images/tags/TAG_SERIES-LEGO+AGE-01.5.desktop.png',
			link: '/product/900000',
		},
		{
			title: { DE: 'Guthaben prüfen' },
			src: '/images/tags/TAG_SERIES-LEGO+AGE-01.5.desktop.png',
			link: '/giftcard_balance',
		},
	],
};

export const MenuLegoAge = {
	title: {
		DE: 'Nach Alter',
		EN: 'By Age',
		FR: 'Par Âge',
	},
	description: {
		headline: {
			DE: 'Für jedes Alter',
			EN: 'For every age',
			FR: 'Pour chaque âge',
		},
		text: {
			DE: 'Durchsuchen Sie unser Produktangebot, gefiltert nach der Altersempfehlung des Herstellers.',
			// prettier-ignore
			EN:
				'Browse our product range filtered by the manufacturer\'s age recommendation.',
			// prettier-ignore
			FR: 'Parcourez notre gamme de produits filtrée par la recommandation d\'âge du fabricant.',
		},
	},
	src: '/images/tags/TAG_SERIES-LEGO+AGE.png',
	items: [
		{
			title: { ALL: '1½+' },
			grouptags: ['AGE/01.5'],
			src: '/images/tags/TAG_SERIES-LEGO+AGE-01.5.desktop.png',
		},
		{
			title: { ALL: '4+' },
			grouptags: ['AGE/04'],
			src: '/images/tags/TAG_SERIES-LEGO+AGE-04.desktop.png',
		},
		{
			title: { ALL: '6+' },
			grouptags: ['AGE/06'],
			src: '/images/tags/TAG_SERIES-LEGO+AGE-06.desktop.png',
		},
		{
			title: { ALL: '9+' },
			grouptags: ['AGE/09'],
			src: '/images/tags/TAG_SERIES-LEGO+AGE-09.desktop.png',
		},
		{
			title: { ALL: '13+' },
			grouptags: ['SERIES/LEGO/', 'AGE/13'],
			src: '/images/tags/TAG_SERIES-LEGO+AGE-13.desktop.png',
		},
		{
			title: { DE: 'Erwachsene', EN: 'Adult' },
			grouptags: ['SERIES/LEGO/', 'AGE/18'],
			src: '/images/tags/TAG_SERIES-LEGO+AGE-18.desktop.png',
		},
	],
};

export const MenuCobiSeries = {
	title: {
		DE: 'COBI Themenwelten',
		EN: 'COBI Themes',
		FR: 'COBI Thèmes',
	},
	items: cobiCategories.map((entry) => {
		const urlParams = new URLSearchParams(entry.href);
		const params = Object.fromEntries(urlParams.entries());
		return {
			title: entry.text,
			grouptags: [_.get(params, '/group?grouptags')],
		};
	}),
};
export const MenuLegoSeries = {
	title: {
		DE: 'LEGO® Themenwelten',
		EN: 'LEGO® Themes',
		FR: 'LEGO® Thèmes',
	},
	description: {
		headline: { DE: 'Im Überblick', EN: 'At a glance' },
		text: {
			DE: 'Entdecken Sie die große LEGO-Themenvielfalt von Architecture bis hin zu Technic.',
			EN: 'Discover the wide variety of LEGO themes from Architecture to Technic.',
		},
	},
	src: '/images/tags/TAG-SERIES-LEGO.png',
	items: [
		{
			title: { ALL: 'Architecture' },
			src: '/images/tags/TAG-SERIES-LEGO-ARCHITECTURE.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/ARCHITECTURE'],
		},
		{
			title: { ALL: 'Art' },
			src: '/images/tags/TAG-SERIES-LEGO-ART.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/ART'],
		},
		{
			title: { DE: 'Die Botanik-Kollektion', EN: 'The botanical collection' },
			src: '/images/tags/TAG-SERIES-LEGO+BOTANICAL_COLLECTION.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/THE_BOTANICAL_COLLECTION'],
		},
		{
			title: { ALL: 'BrickHeadz' },
			src: '/images/tags/TAG-SERIES-LEGO-BRICKHEADZ.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/BRICKHEADZ'],
		},
		{
			title: { ALL: 'City' },
			src: '/images/tags/TAG-SERIES-LEGO-CITY.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/CITY'],
		},
		{
			title: { ALL: 'Classic' },
			src: '/images/tags/TAG-SERIES-LEGO-CLASSIC.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/CLASSIC'],
		},
		{
			title: { ALL: 'Creator 3-in-1' },
			src: '/images/tags/TAG-SERIES-LEGO-CREATOR.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/CREATOR-3-IN-1'],
		},
		{
			title: { ALL: 'DC Comics Super Heroes' },
			src: '/images/tags/TAG-SERIES-LEGO-DC_SUPER_HEROES.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/DC_COMICS_SUPER_HEROES'],
		},
		{
			title: { ALL: 'Disney™' },
			src: '/images/tags/TAG-SERIES-LEGO-DISNEY.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/DISNEY'],
		},
		{
			title: { ALL: 'LEGO® DREAMZzz™' },
			src: '/images/tags/TAG-SERIES-LEGO-DREAMZZZ.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/DREAMZZZ'],
		},
		{
			title: { ALL: 'DOTS' },
			src: '/images/tags/TAG-SERIES-LEGO-DOTS.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/DOTS'],
		},
		{
			title: { ALL: 'DUPLO®' },
			src: '/images/tags/TAG-SERIES-LEGO-DUPLO.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/DUPLO'],
		},
		{
			title: { ALL: 'Friends' },
			src: '/images/tags/TAG-SERIES-LEGO-FRIENDS.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/FRIENDS'],
		},
		{
			title: { ALL: "LEGO® Gabby's Dollhouse" },
			src: '/images/tags/TAG-SERIES-LEGO-GABBYS-DOLLHOUSE.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/GABBYS_DOLLHOUSE'],
		},
		{
			title: { ALL: 'Harry Potter™' },
			src: '/images/tags/TAG-SERIES-LEGO-HARRY_POTTER.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/HARRY_POTTER'],
		},
		{
			title: { ALL: 'Icons (Creator Expert)' },
			src: '/images/tags/TAG-SERIES-LEGO-ICONS.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/CREATOR_EXPERT'],
		},
		{
			title: { ALL: 'Ideas' },
			src: '/images/tags/TAG-SERIES-LEGO-IDEAS.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/IDEAS'],
		},
		{
			title: { ALL: 'Jurassic World™' },
			src: '/images/tags/TAG-SERIES-LEGO-JURASSIC_WORLD.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/JURASSIC_WORLD'],
		},
		{
			title: { ALL: 'Marvel Super Heroes' },
			src: '/images/tags/TAG-SERIES-LEGO-MARVEL_SUPER_HEROES.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/MARVEL_SUPER_HEROES'],
		},
		{
			title: { ALL: 'Minecraft™' },
			src: '/images/tags/TAG-SERIES-LEGO-MINECRAFT.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/MINECRAFT'],
		},
		{
			title: { ALL: 'Minifigures' },
			src: '/images/tags/TAG-SERIES-LEGO-MINIFIGURES.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/MINIFIGURES'],
		},
		// {
		// 	title: { ALL: 'Minions' },
		// 	src: '/images/tags/TAG-SERIES-LEGO-MINIONS.png',
		// 	src_orientation: 'landscape',
		// 	grouptags: ['SERIES/LEGO/MINIONS'],
		// },
		{
			title: { ALL: 'Monkey Kid™' },
			src: '/images/tags/TAG-SERIES-LEGO-MONKIE_KID.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/MONKEY_KID'],
		},
		{
			title: { ALL: 'NINJAGO®' },
			src: '/images/tags/TAG-SERIES-LEGO-NINJAGO.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/NINJAGO'],
		},
		{
			title: { ALL: 'LEGO® Sonic the Hedgehog™' },
			src: '/images/tags/TAG-SERIES-LEGO-SONIC-HEDGEHOG.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/SONIC_THE_HEDGEHOG'],
		},
		{
			title: { ALL: 'Speed Champions' },
			src: '/images/tags/TAG-SERIES-LEGO-SPEED_CHAMPIONS.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/SPEED_CHAMPIONS'],
		},
		{
			title: { ALL: 'Star Wars™' },
			src: '/images/tags/TAG-SERIES-LEGO-STAR_WARS.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/STAR_WARS'],
		},
		{
			title: { ALL: 'Super Mario™' },
			src: '/images/tags/TAG-SERIES-LEGO-SUPER_MARIO.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/SUPER_MARIO'],
		},
		{
			title: { ALL: 'Technic' },
			src: '/images/tags/TAG-SERIES-LEGO-TECHNIC.png',
			src_orientation: 'landscape',
			grouptags: ['SERIES/LEGO/TECHNIC'],
		},
		// {
		// 	title: { ALL: 'Vidiyo' },
		// 	src: '/images/tags/TAG-SERIES-LEGO-VIDIYO.png',
		// 	src_orientation: 'landscape',
		// 	grouptags: ['SERIES/LEGO/VIDIYO'],
		// },
		// {
		// 	title: { ALL: 'BOOST' },
		// 	src: '/images/tags/TAG-SERIES-LEGO-BOOST.png',
		// 	grouptags: ['SERIES/LEGO/BOOST'],
		// },
		{
			title: { ALL: 'Ghostbusters' },
			src: '/images/tags/TAG-SERIES-LEGO-GHOSTBUSTERS.png',
			grouptags: ['SERIES/LEGO/GHOSTBUSTERS'],
		},
		// {
		// 	title: { ALL: 'MINDSTORMS®' },
		// 	src: '/images/tags/TAG-SERIES-LEGO-MINDSTORMS.png',
		// 	grouptags: ['SERIES/LEGO/MINDSTORMS'],
		// },
		{
			title: { DE: 'Alte Sets', EN: 'Hard To Find', FR: 'Anciens sets' },
			grouptags: ['SERIES/LEGO/HARD_TO_FIND'],
		},
		{
			title: { ALL: 'Polybag' },
			grouptags: ['SERIES/LEGO/POLYBAG'],
		},
		{
			title: { ALL: 'Power Functions' },
			grouptags: ['SERIES/LEGO/POWER_FUNCTIONS'],
		},
		{
			title: { ALL: 'Serious Play' },
			grouptags: ['SERIES/LEGO/SERIOUS_PLAY'],
		},
		// {
		// 	title: { DE: 'Verschiedenes', EN: 'Various' },
		// 	grouptags: ['SERIES/LEGO/VARIOUS'],
		// },
		{
			title: { ALL: 'Xtra' },
			grouptags: ['SERIES/LEGO/XTRA'],
		},
		{
			title: { ALL: 'LEGO® Education' },
			grouptags: ['SERIES/LEGO_EDUCATION/'],
		},
	],
};

export const MenuLighting = {
	title: { DE: 'Beleuchtung', EN: 'Lighting', FR: 'Éclairage' },
	description: {
		// prettier-ignore
		headline: { DE: 'Erleuchtend!', EN: 'It\' s enlighting!', FR: 'Illuminer' },
		text: {
			DE: 'Mit diesen Beleuchtungen hauchen Sie Ihren LEGO-Modellen Leben ein.',
			EN: 'With these lights you can add a touch of sparkle to your LEGO models.',
			FR: 'Donnez vie à vos modèles LEGO avec ces illuminateurs.',
		},
	},
	src: '/images/tags/TAG-LIGHTING.png',
	items: [
		{
			title: { DE: 'Für LEGO Sets', EN: 'For Lego sets' },
			grouptags: ['LIGHTING/LEGO'],
		},
		{
			title: { DE: 'DIY-Beleuchtung', EN: 'DIY Lighting' },
			grouptags: ['LIGHTING/DIY'],
		},
		{
			title: { DE: 'Für LEGO Sets 2.0', EN: 'For Lego sets 2.0' },
			grouptags: ['LIGHTING/LEGO2'],
		},
		{
			title: { DE: 'DIY-Beleuchtung 2.0', EN: 'DIY Lighting 2.0' },
			grouptags: ['LIGHTING/DIY2'],
		},
	],
};

export const MenuStorageBoxes = {
	title: {
		DE: 'Lizenzierte Produkte',
		EN: 'Licensed products',
		FR: 'Produits sous licence',
	},
	description: {
		headline: { DE: 'Einfach verstaut', EN: 'Easily stored' },
		text: {
			DE: 'Entdecken Sie spannende LEGO® Produkte für Ihr Zuhause und unterwegs.',
			EN: 'Explore exciting LEGO® products for your home and on the go.',
		},
	},
	src: '/images/tags/TAG-STORAGE.png',
	items: [
		{
			title: {
				DE: 'Aufbewahren & Organisieren',
				EN: 'Storage & Organizing',
				FR: 'Rangement & organisation',
			},
			grouptags: ['LICENSED_PRODUCTS/STORAGE_ORGANIZING'],
		},
		{
			title: {
				DE: 'Einrichtung',
				EN: 'Home Decor',
			},
			grouptags: ['LICENSED_PRODUCTS/HOME_DECOR'],
		},
		{
			title: {
				DE: 'Essen & Trinken',
				EN: 'Food & Drink',
				FR: 'Nourriture et boissons',
			},
			grouptags: ['LICENSED_PRODUCTS/FOOD_DRINK'],
		},
		{
			title: {
				DE: 'Taschen & Gepäck',
				EN: 'Bags & Luggage',
				FR: 'Sacs & bagages',
			},
			grouptags: ['LICENSED_PRODUCTS/BAGS_LUGGAGE'],
		},
		{
			title: {
				DE: 'Bürobedarf & Schreibwaren',
				EN: 'Office Supplies & Stationery',
				FR: 'Fournitures de bureau',
			},
			grouptags: ['LICENSED_PRODUCTS/OFFICE_SUPPLIES'],
		},
		{
			title: {
				DE: 'Nachttischlampen & Nachtlichter',
				EN: 'Bedside Lamps & Night Lights',
				FR: 'Lampes de chevet & veilleuses',
			},
			grouptags: ['LICENSED_PRODUCTS/BEDSIDE_LAMPS'],
		},
		{
			title: {
				DE: 'Schlüsselanhänger',
				EN: 'Keychains',
				FR: 'Porte-clés',
			},
			grouptags: ['LICENSED_PRODUCTS/KEYCHAIN'],
		},
	],
};

export const MenuBooks = {
	title: { DE: 'Bücher', EN: 'Books', FR: 'Livres' },
	items: [
		{ title: { ALL: 'LEGO®' }, grouptags: ['BOOKS/LEGO'] },
		{
			title: { ALL: 'Pokémon' },
			grouptags: ['BOOKS/POKEMON'],
		},
	],
};
